import Footer from "../../components/footer";

import Navbar from "../../components/navbar";
import { useTranslation } from "react-i18next";
import { List, Select, Table, Modal } from "antd";
import { Link } from "react-router-dom";
import { fetchSelectList, fetchRankingList } from "../../api/rank";
import { useEffect, useState } from "react";
import isMobile from "ismobilejs";
import { MainButton } from "../../components/button";
import { StudyForm } from "../../components/studyform";
import { HeadingBlock } from "../../components/headingBlock";

const University = () => {
  const { t, i18n } = useTranslation("university");
  const { t: tcommon } = useTranslation("common");

  const [countries, setCountries] = useState([]);
  const [years, setYears] = useState([]);
  const [isModalOpen, setOpen] = useState(false);

  const [currentCountry, setCurrentCountry] = useState("Australia");
  const [currentYear, setCurrentYear] = useState();

  const [data, setData] = useState([]);
  const [info, setInfo] = useState({});

  const mobile = isMobile();

  const lang = i18n.language;

  const columns = mobile.any
    ? [
        {
          title: t("country-rank"),
          dataIndex: "CountryRanking",
          key: "CountryRanking",
          width: 75,
        },
        {
          title: t("times-rank"),
          dataIndex: "Rank",
          key: "Rank",
          width: 60,
        },

        {
          title: t("institution"),
          dataIndex: lang === "en" ? "University" : "UniversityChinese",
          key: "University",
          render: (text, record, index) => {
            return (
              <div className="flex items-center gap-10">
                <span className="font-bold">{text}</span>
              </div>
            );
          },
        },
        {
          title: t("city"),
          dataIndex: "City",
          key: "City",
        },
      ]
    : [
        {
          title: t("country-rank"),
          dataIndex: "CountryRanking",
          key: "CountryRanking",
          width: 150,
        },
        {
          title: t("times-rank"),
          dataIndex: "Rank",
          key: "Rank",
          width: 140,
        },

        {
          title: t("institution"),
          dataIndex: lang === "en" ? "University" : "UniversityChinese",
          key: "University",
          render: (text, record, index) => {
            return (
              <div className="flex items-center gap-10">
                <span className="font-bold">{text}</span>
              </div>
            );
          },
        },
        {
          title: t("state"),
          dataIndex: "State",
          key: "State",
          width: 250,
        },
        {
          title: t("city"),
          dataIndex: "City",
          key: "City",
          width: 120,
        },
      ];

  useEffect(() => {
    const getSelectList = async () => {
      const res = await fetchSelectList();
      setCountries(res.country_list);
      setCurrentCountry(res.country_list[0].country_name);
      setYears(res.year_list);
      setCurrentYear(res.year_list[0]);
    };
    getSelectList();
  }, []);

  useEffect(() => {
    const getList = async () => {
      const res = await fetchRankingList({
        country: currentCountry,
        year: currentYear,
      });
      setData(res.rank_list);
      setInfo(res);
    };
    if (currentCountry && currentYear) {
      getList();
    }
  }, [currentCountry, currentYear]);

  return (
    <>
      <Navbar />
      <div>
        <HeadingBlock
          bg="/img/Find_my_university.jpg"
          img="/icon/Icon-Uni Prep@2x.png"
          header={t("title")}
          desc={tcommon("finduniversity-desc2")}
        />
        <div className="flex justify-center pb-10 pt-10">
          <div className="px-5 md:px-20 w-full md:max-w-screen-xl">
            <div className="text-xl font-bold">{t("sub-title")}</div>
            <div className="text-lg pb-5">{t("sub-title-1")}</div>
            <div className="flex gap-10">
              <List bordered>
                <List.Item
                  className="list-factor"
                  style={{ backgroundColor: "#f5f5f5" }}
                >
                  <span className="w-[320px]">
                    Teaching (the learning environment): 29.5%
                  </span>
                  <ul className="">
                    <li>Teaching reputation: 15%</li>
                    <li>Staff-to-student ratio: 4.5%</li>
                    <li>Doctorate-to-bachelor’s ratio: 2%</li>
                    <li>Doctorates-awarded-to-academic-staff ratio: 5.5%</li>
                    <li>Institutional income: 2.5%</li>
                  </ul>
                </List.Item>
                <List.Item
                  className="list-factor"
                  style={{ backgroundColor: "" }}
                >
                  <span className="w-[320px]">Research environment: 29%</span>
                  <ul className="">
                    <li>Research reputation: 18%</li>
                    <li>Research income: 5.5%</li>
                    <li>Research productivity: 5.5%</li>
                  </ul>
                </List.Item>
                <List.Item
                  className="list-factor"
                  style={{ backgroundColor: "#f5f5f5" }}
                >
                  <span className="w-[320px]">Research quality: 30%</span>
                  <ul className="">
                    <li>Citation impact: 15%</li>
                    <li>Research strength: 5%</li>
                    <li>Research excellence: 5%</li>
                    <li>Research influence: 5%</li>
                  </ul>
                </List.Item>
                <List.Item
                  className="list-factor"
                  style={{ backgroundColor: "" }}
                >
                  <span className="w-[320px]">Industry: 4%</span>
                  <ul className="">
                    <li>Industry income: 2%</li>
                    <li>Patents: 2%</li>
                  </ul>
                </List.Item>
                <List.Item
                  className="list-factor"
                  style={{ backgroundColor: "#f5f5f5" }}
                >
                  <span className="w-[320px]">International outlook: 7.5%</span>
                  <ul className="">
                    <li>Proportion of international students: 2.5%</li>
                    <li>Proportion of international staff: 2.5%</li>
                    <li>International collaboration: 2.5%</li>
                  </ul>
                </List.Item>
              </List>
              <div className="md:flex flex-1 items-center justify-center hidden">
                <img src="/img/theWord.png" alt="" />
              </div>
            </div>
            <div className="text-xl font-bold py-5">{t("destination")}</div>
            <div className="flex gap-5">
              <Select
                size="large"
                value={currentCountry}
                style={{ width: 240 }}
                onChange={(v) => setCurrentCountry(v)}
                options={countries.map((i) => ({
                  value: i.country_name,
                  label: i.country_name,
                }))}
              />
              <Select
                size="large"
                value={currentYear}
                style={{ width: 240 }}
                onChange={(v) => setCurrentYear(v)}
                options={years
                  .sort((a, b) => Number(b) - Number(a))
                  .slice(0, 5)
                  .map((i) => ({ value: i, label: i }))}
              />
            </div>
            {/* <div>
              <img className="rounded-lg pt-5 w-full" src={info.country_image_url} alt="university" />
            </div> */}
            <div className="text-xl font-bold py-5">
              {t("partner")} -{" "}
              <span className="main-color">{info.country_name}</span>
            </div>
            <div>
              <Table
                tableLayout={mobile.any ? "fixed" : "auto"}
                size={mobile.any ? "small" : "large"}
                className="w-full"
                dataSource={data}
                columns={columns}
                rowClassName={(record, index) => {
                  let className = "light-row";
                  if (index % 2 === 1) className = "dark-row";
                  return className;
                }}
              />
            </div>
            <MainButton size="large" className="" onClick={() => setOpen(true)}>
              {t("help-me-abroad")}
            </MainButton>
            <div className="text-xl font-bold py-5">
              {t("top-university")} - {info.country_name}
            </div>
            <div className="flex flex-wrap justify-center gap-5">
              {info.topres
                ? info.topres.map((i) => (
                    <div
                      key={i.university}
                      className="w-1/5 shadow flex justify-center items-center p-1 rounded"
                    >
                      <Link
                        to={`/studyoversea/detail/${i.university}/${currentYear}`}
                      >
                        {i.logo_url ? <img src={i.logo_url} /> : i.university}
                      </Link>
                    </div>
                  ))
                : null}
            </div>
          </div>
        </div>
      </div>
      <Modal
        {...(mobile.any
          ? { style: { top: 0, margin: 0, maxWidth: "100%", height: "100%" } }
          : {})}
        footer={null}
        open={isModalOpen}
        onCancel={() => {
          setOpen(false);
        }}
      >
        <div className="pt-7">
          <StudyForm />
        </div>
      </Modal>
    </>
  );
};

export default University;
