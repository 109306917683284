import { useEffect, useState, useRef } from "react";
import { useUser } from "@clerk/clerk-react";
import { Avatar, Button, List, Skeleton, Modal, Input, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { fetchPost } from "../../utils/fetch";
import { newhost } from "../../config/fetch";
import { upload } from "@vercel/blob/client";
import TextArea from "antd/es/input/TextArea";

export const Events = () => {
  const { isSignedIn, user } = useUser();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [list, setList] = useState([]);
  const [courses, setCourses] = useState([]);
  const [titlec, settitlec] = useState("");
  const [title, settitle] = useState("");
  const [presenter, setpresenter] = useState("");
  const [descc, setdescc] = useState("");
  const [desc, setdesc] = useState("");
  const [url, seturl] = useState("");
  const [priority, setpriority] = useState("");
  const [icon, seticon] = useState("");
  const [price, setprice] = useState("");
  const [avatar, setavatar] = useState("");
  const [id, setid] = useState("");
  const [programid, setprogramid] = useState("");

  const logo_Ref = useRef(null);
  const avatar_Ref = useRef(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const res = await fetch(newhost + "/program");
    const resp = await res.json();
    setList(resp.data.filter((i) => i.programtype === "event"));
  };

  const submitConfig = async () => {
    const res = await fetchPost(newhost + "/events", {
      id,
      programid,
      title,
      titlec,
      desc,
      descc,
      url,
      priority: priority ? priority : 0,
      icon,
      avatar,
      price,
      presenter,
    });
    alert("update success");
    onChangeProgram(programid);
  };

  const handleFileUpload = async (e, ref, name) => {
    console.log(e, name);
    const file = ref.current.files[0];
    const newBlob = await upload(file.name, file, {
      access: "public",
      handleUploadUrl: newhost + "/upload",
    });
    console.log(newBlob.url);
    if (name === "avatar_url") {
      setavatar(newBlob.url);
    } else {
      seticon(newBlob.url);
    }

    // setImgs({ ...imgs, [name]: newBlob.url });
  };

  const handleConfig = (id) => {
    setIsModalOpen(true);
    const target = courses.filter((i) => i.id === id)[0];
    if (target) {
      setid(id);
      settitle(target.title);
      settitlec(target.titlec);
      setdesc(target.desc);
      setdescc(target.descc);
      setpriority(target.priority);
      seturl(target.url);
      seticon(target.icon);
      setpresenter(target.presenter);
      setavatar(target.avatar);
      setprice(target.price);
    }
  };

  const handleDelete = async (id) => {
    const res = await fetch(newhost + "/events/remove?id=" + id);
    onChangeProgram(programid);
  };

  const clearModal = () => {
    setid("");
    settitlec("");
    settitle("");
    setdesc("");
    setdescc("");
    seturl("");
    setpriority("");
    seticon("");
    setprice("");
  };

  const onChangeProgram = async (id) => {
    setprogramid(id);
    const path = id ? "/events?programid=" : "/events";
    const i = id || "";
    const res = await fetch(newhost + path + i);
    const resp = await res.json();
    console.log(resp);
    setCourses(resp.data);
  };

  return (
    <>
      <div>
        <div className="font-bold pb-2">Select a Program to Config</div>
        <Select
          showSearch
          size="large"
          className="w-[300px]"
          placeholder="Select a program to Config"
          optionFilterProp="label"
          onChange={onChangeProgram}
          options={list.map((i) => ({ value: i.id, label: i.title }))}
        />
        <div className="font-bold text-lg pt-5">
          Config exsiting course or add new course
        </div>
        <List
          size="large"
          className="min-w-[1000px]"
          itemLayout="horizontal"
          dataSource={courses}
          renderItem={(item) => (
            <List.Item
              actions={[
                <Button type="link" onClick={() => handleConfig(item.id)}>
                  Edit
                </Button>,
                <Button
                  type="link"
                  danger
                  onClick={() => handleDelete(item.id)}
                >
                  Remove
                </Button>,
              ]}
            >
              <Skeleton avatar title={false} loading={item.loading} active>
                <List.Item.Meta
                  avatar={
                    <div className="flex flex-col justify-center items-center">
                      <Avatar src={item.avatar} />
                      <div>{item.presenter}</div>
                    </div>
                  }
                  title={<a href="https://ant.design">{item.title}</a>}
                  description={item.desc.slice(0, 100) + "......"}
                />
                <div className="overflow-ellipsis overflow-hidden whitespace-nowrap truncate pl-5">
                  AUD ${item.price}
                </div>
              </Skeleton>
            </List.Item>
          )}
        />
        <Button
          type="primary"
          size="large"
          onClick={() => {
            setIsModalOpen(true);
            setid(new Date().getTime());
          }}
        >
          Add new Event
        </Button>
      </div>
      <Modal
        title="Config Event"
        open={isModalOpen}
        okText="Update Event Config"
        onOk={() => {
          submitConfig();
          clearModal();
          setIsModalOpen(false);
        }}
        onCancel={() => {
          setIsModalOpen(false);
        }}
      >
        <div className="font-bold text-md py-2">Event Title</div>
        <Input value={title} onChange={(e) => settitle(e.target.value)} />
        <div className="font-bold text-md py-2">Event Title - Chinese</div>
        <Input value={titlec} onChange={(e) => settitlec(e.target.value)} />
        <div className="font-bold text-md py-2">Event Description</div>
        <TextArea value={desc} onChange={(e) => setdesc(e.target.value)} />
        <div className="font-bold text-md py-2">
          Event Description - Chinese
        </div>
        <TextArea value={descc} onChange={(e) => setdescc(e.target.value)} />
        <div className="font-bold text-md py-2">Event Presenter</div>
        <Input
          value={presenter}
          onChange={(e) => setpresenter(e.target.value)}
        />
        <div className="font-bold text-md py-2">Presenter Image</div>
        <input
          name="file"
          ref={avatar_Ref}
          accept="image/*"
          type="file"
          onChange={(e) => handleFileUpload(e, avatar_Ref, "avatar_url")}
        />
        {avatar ? (
          <img className="rounded-full w-20 h-20" src={avatar} alt="12" />
        ) : null}
        <div className="font-bold text-md py-2">Event jump URL</div>
        <Input value={url} onChange={(e) => seturl(e.target.value)} />
        <div className="font-bold text-md py-2">Price AUD</div>
        <Input value={price} onChange={(e) => setprice(e.target.value)} />
        <div className="font-bold text-md py-2">Event Priority</div>
        <Input value={priority} onChange={(e) => setpriority(e.target.value)} />
        <div className="font-bold text-md py-2">Event Image</div>
        <input
          name="file"
          ref={logo_Ref}
          accept="image/*"
          type="file"
          onChange={(e) => handleFileUpload(e, logo_Ref, "logo_url")}
        />
        {icon ? <img src={icon} alt="12" /> : null}
      </Modal>
    </>
  );
};
