export const subjects = [
  'Accounting, finance and banking',
  'Agricultural sciences',
  'Animal care and veterinary science',
  'Archaeology and anthropology',
  'Architecture, building and planning',
  'Art and design',
  'Biology and life sciences',
  'Business and management studies',
  'Chemistry',
  'Classics',
  'Computer sciences and IT',
  'Early learning',
  'Earth and ocean sciences',
  'Economics',
  'Education/Teacher training',
  'Engineering',
  'Environmental science and ecology',
  'Food sciences',
  'Geography',
  'Geology',
  'Health sciences',
  'History',
  'Humanities',
  'Information science and librarianship',
  'Languages',
  'Law and legal studies',
  'Linguistics',
  'Literature and creative writing',
  'Marketing and advertising studies',
  'Materials science',
  'Mathematics',
  'Media studies and publishing',
  'Medicine',
  'Medicine related',
  'Music and performing arts',
  'Natural sciences',
  'Nursing',
  'Pharmacy and pharmacology',
  'Philosophy',
  'Physics',
  'Politics',
  'Psychology',
  'Social sciences',
  'Sports science',
  'Theology and religious studies',
  'Tourism, hospitality and event management',
  'Transport engineering and logistics',
  'Other',
]