import Navbar from "../components/navbar";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useUser } from "@clerk/clerk-react";
import { adminList } from "../constants/admin";
import { Button, Segmented, Table, Input } from "antd";
import { University } from "./dashboard/university";
import { EditUniversity } from "./dashboard/edituniinfo";
import { Programs } from "./dashboard/programs";
import { Courses } from "./dashboard/courses";
import { Events } from "./dashboard/events";

const Dashboard = () => {
  const { t } = useTranslation("common");
  const { isSignedIn, user } = useUser();
  const [alignValue, setAlignValue] = useState("Update Universitys");

  if (!isSignedIn || !adminList.includes(user.emailAddresses[0].emailAddress)) {
    return "Only Admin users";
  }

  return (
    <>
      <Navbar />
      <div className="flex w-full justify-center pt-5 pb-20">
        <div className="w-full md:max-w-screen-2xl flex px-10 ">
          <div>
            <div className="font-bold text-2xl pb-10">
              Hello, {user.fullName}
            </div>
            <Segmented
              size="large"
              defaultValue="Update Universitys"
              style={{ marginBottom: 8 }}
              onChange={(value) => setAlignValue(value)}
              options={[
                "Update Universitys",
                "Edit University Info",
                "Edit Programs",
                "Edit Courses",
                "Edit Events",
              ]}
            />
            <div>{alignValue === "Update Universitys" && <University />}</div>
            <div>
              {alignValue === "Edit University Info" && <EditUniversity />}
            </div>
            <div>{alignValue === "Edit Programs" && <Programs />}</div>
            <div>{alignValue === "Edit Courses" && <Courses />}</div>
            <div>{alignValue === "Edit Events" && <Events />}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
