import axios from "axios";
import {host, newhost} from "../config/fetch";

export const fetchCourses = async (programid) => {
  const res = await axios.get(`${newhost}/course`, {
    params: {
      programid
    }
  });
  return res.data;
};

export const fetchEvents = async (programid) => {
  const res = await axios.get(`${newhost}/events`, {
    params: {
      programid
    }
  });
  return res.data;
};

export const fetchCourseInfo = async ({id}) => {
  const res = await axios.get(`${newhost}/course`, {
    params: {
      id
    },
  });
  console.log(111, res);
  return res.data.data[0];
};
export const fetchEventInfo = async ({id}) => {
  const res = await axios.get(`${newhost}/events`, {
    params: {
      id
    },
  });
  console.log(111, res);
  return res.data.data[0];
};

export const fetchProgram = async (programid) => {
  const res = await axios.get(`${newhost}/program`, {
    params: {
      programid
    }
  });
  return res.data;
};