import Footer from "../../components/footer";
import { useEffect, useState, useMemo } from "react";
import Navbar from "../../components/navbar";
import { CourseCard } from "../../components/courseCard";
import { useTranslation } from "react-i18next";
import { HeadingBlock } from "../../components/headingBlock";
import { LoadingOutlined } from "@ant-design/icons";
import { Switch, Route, useParams } from "react-router-dom";
import { fetchEvents, fetchProgram } from "../../api/masterclass";
import { Enrol } from "./enrol";

const Events = () => {
  const { t } = useTranslation("common");
  const { id } = useParams();
  const [programList, setProgramList] = useState([]);
  const [programinfo, setprograminfo] = useState({});
  const [initprogramList, setInitProgramList] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const res = await fetchEvents(id);
      setProgramList(res.data);
      setInitProgramList(res.data);
    };
    fetch();
  }, []);

  useEffect(() => {
    const fetch = async () => {
      const res = await fetchProgram(id);
      setprograminfo(res.data[0]);
    };
    fetch();
  }, []);

  return (
    <>
      <Navbar />
      <div>
        <HeadingBlock
          bg={programinfo.picture}
          img={programinfo.icon}
          header={programinfo.title}
          desc={programinfo.desc}
        />
        <div className="flex justify-center md:p-10 mb-5">
          <div className="w-5/6 md:max-w-screen-xl">
            {programList.length === 0 ? (
              <div className="flex justify-center">
                <span className="justify-center items-center">
                  <LoadingOutlined style={{ fontSize: "30px" }} />
                  <span className="text-xl">
                    {" "}
                    &nbsp;&nbsp;&nbsp; Loading Data
                  </span>
                </span>
              </div>
            ) : null}
            <div className="flex flex-col md:flex-row w-full flex-wrap gap-5">
              {programList.map((courseInfo) => (
                <CourseCard type="event" courseInfo={courseInfo} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const EventsClassRouter = () => (
  <div className="flex flex-col min-h-screen">
    <div className="flex-1">
      <Switch>
        <Route path={`/events/enrol/:id`}>
          <Enrol />
        </Route>
        <Route path={`/events/program/:id`}>
          <Events />
        </Route>
      </Switch>
    </div>
    <Footer />
  </div>
);

export default EventsClassRouter;
