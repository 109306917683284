import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RightOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { newhost } from "../config/fetch";

export const LessonItem = ({
  data,
  icon,
  title,
  desc,
  link,
  isCol,
  roundIcon,
  smallIcon,
}) => {
  console.log(1234, data);
  const { t } = useTranslation("common");
  return (
    <div className="flex justify-center mt-5 border rounded">
      <div
        className={`flex shadow rounded-lg bg-white w-full ${
          isCol ? "flex-col md:w-80 items-center" : ""
        }`}
      >
        <div
          style={{
            width: isCol ? "200px" : "200px",
            minWidth: isCol ? "200px" : "200px",
          }}
          className={`hidden md:flex justify-center items-center ${
            isCol ? "flex justify-center p-5" : "p-5 border-r"
          }`}
        >
          <img
            width={smallIcon ? "155" : roundIcon ? "140" : "130"}
            src={icon}
            className={`${roundIcon ? "rounded-full" : ""}`}
            alt=""
          />
        </div>
        <div className={isCol ? "p-8 pt-2" : "p-10"}>
          <div className="text-xl font-bold main-color">{title}</div>
          <div>{desc}</div>
          <div className="pt-5">
            {data && data.programtype ? (
              <Link
                to={
                  data.programtype === "lesson"
                    ? `/lesson/${data.id}`
                    : data.programtype === "event"
                    ? `/events/program/${data.id}`
                    : link
                }
              >
                <Button
                  style={{ background: "#f05622", color: "#fff" }}
                  className="flex items-center"
                >
                  {t("read-more")}
                  <RightOutlined />
                </Button>
              </Link>
            ) : (
              <Link to={link}>
                <Button
                  style={{ background: "#f05622", color: "#fff" }}
                  className="flex items-center"
                >
                  {t("read-more")}
                  <RightOutlined />
                </Button>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const Lesson = () => {
  const { t, i18n } = useTranslation("common");

  const [list, setList] = useState([]);

  const fetchData = async () => {
    const res = await fetch(newhost + "/program");
    const resp = await res.json();
    if (!resp.data) {
      return;
    }
    setList(resp.data.sort((a, b) => Number(a.priority) - Number(b.priority)));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div
      style={{ backgroundColor: "#f6f6f6" }}
      className="py-5 flex justify-center"
    >
      <div className="w-5/6 md:max-w-screen-lg">
        {list.map((i) => (
          <LessonItem
            data={i}
            icon={i.icon}
            title={i18n.language === "en" ? i.title : i.titlec}
            desc={i18n.language === "en" ? i.desc : i.descc}
            link={i.url}
          />
        ))}
      </div>
    </div>
  );
};

export default Lesson;
