import { useEffect, useState } from "react";
import { useUser } from "@clerk/clerk-react";
import { Button, Segmented, Table, Input } from "antd";
import * as XLSX from "xlsx";
import { fetchPost } from "../../utils/fetch";
import { newhost } from "../../config/fetch";

const columns = [
  {
    title: "Year",
    dataIndex: "year",
    key: "year",
  },
  {
    title: "CountryRanking",
    dataIndex: "CountryRanking",
    key: "CountryRanking",
  },
  {
    title: "Rank",
    dataIndex: "Rank",
    key: "Rank",
  },
  {
    title: "Priority",
    dataIndex: "Priority",
    key: "Priority",
  },
  {
    title: "University",
    dataIndex: "University",
    key: "University",
  },
  {
    title: "UniversityChinese",
    dataIndex: "UniversityChinese",
    key: "UniversityChinese",
  },
  {
    title: "Country",
    dataIndex: "Country",
    key: "Country",
  },
  {
    title: "State",
    dataIndex: "State",
    key: "State",
  },
  {
    title: "City",
    dataIndex: "City",
    key: "City",
  },
];

export const University = () => {
  const { isSignedIn, user } = useUser();

  const [unidata, setUnitData] = useState([]);
  const [year, setYear] = useState("2024");

  const submitUniversity = async () => {
    await fetchPost(newhost + "/university", {
      list: unidata.map((i) => ({ ...i, year })),
    });
    alert('update success')
  };

  const handleExport = (data) => {
    // 将数据转换为工作表
    const worksheet = XLSX.utils.json_to_sheet(data);
    // 创建一个新的工作簿
    const workbook = XLSX.utils.book_new();
    // 将工作表添加到工作簿
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    // 生成Excel文件并触发下载
    XLSX.writeFile(workbook, "university_ranking_database.xlsx");
  };

  const exportData = async () => {
    const res = await fetch(newhost + "/university");
    const resp = await res.json();
    console.log(resp);
    handleExport(
      resp.data.map((i) => {
        delete i["_id"];
        return i;
      })
    );
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetNames = workbook.SheetNames;
        const firstSheetName = sheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);
        console.log(jsonData);
        setUnitData(jsonData);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  return (
    <>
      <div>
        <div className="font-bold text-lg pt-5">
          Export exsiting data to excel file
        </div>
        <div className="text-slate-500 pb-5">
          After export exsiting data, you can modify and resubmit it.
        </div>
        <div>
          <Button type="primary" onClick={exportData}>Export</Button>
        </div>
        <div className="font-bold text-lg pt-5">
          Upload excel file to parse University
        </div>
        <div className="text-slate-500 pb-5">
          Only support .xlsx, .xls files, the new file data will cover old data
        </div>
        <div className="flex justify-between">
          <input type="file" accept=".xlsx, .xls" onChange={handleFileChange} />
          {unidata.length > 0 && (
            <span className="flex gap-2">
              {/* <Input
                className="w-32"
                placeholder="Year eg.2024"
                value={year}
                onChange={(e) => setYear(e.target.value)}
              />  */}
              <Button onClick={submitUniversity}>Submit to Database</Button>
            </span>
          )}
        </div>

        {unidata.length > 0 && (
          <Table
            size="middle"
            className="pt-5"
            dataSource={unidata}
            columns={columns}
          />
        )}
      </div>
    </>
  );
};
