import { useState } from "react";
import { Button } from "antd";
import { useHistory } from "react-router-dom";
import { MainButton } from "./button";
import { GlobalOutlined, FileTextOutlined } from "@ant-design/icons";

export const CourseCard = ({ courseInfo, type }) => {
  const {
    title: course_name,
    icon: img_url,
    lang_info,
    desc: long_info,
    presenter: presenter_name,
    price: price_info,
    avatar: profile_img_url,
    desc: short_info,
    id,
  } = courseInfo;

  let history = useHistory();

  const handleEnrol = () => {
    history.push(`/${type === "event" ? "events" : "masterclass"}/enrol/${id}`);
  };

  const handleMore = () => {
    history.push(`/${type === "event" ? "events" : "masterclass"}/enrol/${id}`);
  };

  return (
    <div className="shadow rounded-lg flex flex-col justify-between overflow-hidden w-full md:w-[calc(33%-1.25rem)] mb-5">
      <div className="">
        <div
          className="h-[121px] overflow-hidden"
          style={{
            backgroundImage: `url(${img_url})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>

        <div className="p-5">
          <div
            className="font-bold text-xl mb-2 py-2 main-color"
            style={{ minHeight: "72px" }}
          >
            {course_name}
          </div>
          <div className="flex gap-2">
            <div className="w-1/4">
              <img src={profile_img_url} className="rounded-full w-20 h-20" />
            </div>
            <div>
              <div className="font-bold text-lg">Presenter</div>
              <div>{presenter_name}</div>
            </div>
          </div>
          <div className="border-b py-2">
            <div className="font-bold text-lg flex items-center gap-2">
              <GlobalOutlined style={{ fontSize: "16px" }} />
              Language
            </div>
            <div className="text-gray-700">{"English"}</div>
          </div>
          <div className=" pt-2">
            <div className="font-bold text-lg flex items-center gap-2">
              <FileTextOutlined style={{ fontSize: "16px" }} />
              Description
            </div>
            <div
              className="text-gray-700"
              dangerouslySetInnerHTML={{ __html: short_info }}
            ></div>
          </div>
        </div>
      </div>
      <div className="p-5 pt-0">
        <div className="flex flex-wrap mb-2">
          {/* <span className='font-bold'>Price:&nbsp;&nbsp;</span> */}
          <span className="font-bold">A${price_info} (included GST)</span>
        </div>
        <div className="flex justify-end">
          {/* <Button onClick={() => handleMore()}></Button> */}
          <MainButton onClick={() => handleEnrol()}>Read More</MainButton>
        </div>
      </div>
    </div>
  );
};
