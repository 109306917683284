import { useEffect, useState, useRef } from "react";
import { useUser } from "@clerk/clerk-react";
import { Avatar, Button, List, Skeleton, Modal, Input, Radio } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { fetchPost } from "../../utils/fetch";
import { newhost } from "../../config/fetch";
import { upload } from "@vercel/blob/client";
import TextArea from "antd/es/input/TextArea";

export const Programs = () => {
  const { isSignedIn, user } = useUser();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [list, setList] = useState([]);
  const [titlec, settitlec] = useState("");
  const [title, settitle] = useState("");
  const [programtype, setprogramtype] = useState("");
  const [descc, setdescc] = useState("");
  const [desc, setdesc] = useState("");
  const [url, seturl] = useState("");
  const [priority, setpriority] = useState("");
  const [icon, seticon] = useState("");
  const [picture, setpicture] = useState("");
  const [id, setid] = useState("");

  const logo_Ref = useRef(null);
  const picture_Ref = useRef(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const res = await fetch(newhost + "/program");
    const resp = await res.json();
    if (!resp.data) {
      return
    }
    setList(resp.data.sort((a, b) => Number(a.priority) - Number(b.priority)));
  };

  const submitConfig = async () => {
    const res = await fetchPost(newhost + "/program", {
      id,
      title,
      titlec,
      desc,
      descc,
      url,
      programtype,
      priority: priority ? priority : 0,
      icon,
      picture,
    });
    alert("update success");
    fetchData();
  };

  const handleFileUpload = async (e, ref, name) => {
    const file = ref.current.files[0];
    const newBlob = await upload(file.name, file, {
      access: "public",
      handleUploadUrl: newhost + "/upload",
    });

    if (name === "logo_url") {
      seticon(newBlob.url);
    }
    if (name === "picture_url") {
      setpicture(newBlob.url);
    }
  };

  const handleConfig = (id) => {
    setIsModalOpen(true);
    const target = list.filter((i) => i.id === id)[0];
    if (target) {
      setid(id);
      settitle(target.title);
      settitlec(target.titlec);
      setdesc(target.desc);
      setdescc(target.descc);
      setprogramtype(target.programtype);
      setpriority(target.priority);
      seturl(target.url);
      seticon(target.icon);
      setpicture(target.picture);
    }
  };

  const handleDelete = async (id) => {
    const res = await fetch(newhost + "/program/remove?id=" + id);
    fetchData();
  };

  const clearModal = () => {
    setid("");
    settitlec("");
    settitle("");
    setdesc("");
    setdescc("");
    seturl("");
    setprogramtype("");
    setpriority("");
    seticon("");
    setpicture("");
  };

  return (
    <>
      <div>
        <div className="font-bold text-lg pt-5">
          Config exsiting program or add new program
        </div>
        <List
          size="large"
          className="min-w-[1000px]"
          itemLayout="horizontal"
          dataSource={list}
          renderItem={(item) => (
            <List.Item
              actions={[
                <Button type="link" onClick={() => handleConfig(item.id)}>
                  Edit
                </Button>,
                <Button
                  type="link"
                  danger
                  onClick={() => handleDelete(item.id)}
                >
                  Remove
                </Button>,
              ]}
            >
              <Skeleton avatar title={false} loading={item.loading} active>
                <List.Item.Meta
                  avatar={<Avatar src={item.icon} />}
                  title={<a href="https://ant.design">{item.title}</a>}
                  description={item.desc.slice(0, 100) + "......"}
                />
                <div className="overflow-ellipsis overflow-hidden whitespace-nowrap truncate pl-5">
                  {item.url}
                </div>
                <div className="overflow-ellipsis overflow-hidden whitespace-nowrap truncate pl-5">
                  P{item.priority}
                </div>
                <div className="overflow-ellipsis overflow-hidden whitespace-nowrap truncate pl-5">
                  {item.programtype || 'default'}
                </div>
              </Skeleton>
            </List.Item>
          )}
        />
        <Button
          type="primary"
          size="large"
          onClick={() => {
            setIsModalOpen(true);
            setid(new Date().getTime());
          }}
        >
          Add new Program
        </Button>
      </div>
      <Modal
        title="Config Program"
        open={isModalOpen}
        okText="Update Program Config"
        onOk={() => {
          submitConfig();
          clearModal();
          setIsModalOpen(false);
        }}
        onCancel={() => {
          setIsModalOpen(false);
        }}
      >
        <div className="font-bold text-md py-2">Program Type</div>
        <Radio.Group
          defaultValue="url"
          buttonStyle="solid"
          value={programtype}
          onChange={(e) => setprogramtype(e.target.value)}
        >
          <Radio.Button value="event">Event</Radio.Button>
          <Radio.Button value="lesson">Lesson</Radio.Button>
          <Radio.Button value="url">URL</Radio.Button>
        </Radio.Group>
        <div className="font-bold text-md py-2">Program Title</div>
        <Input value={title} onChange={(e) => settitle(e.target.value)} />
        <div className="font-bold text-md py-2">Program Title - Chinese</div>
        <Input value={titlec} onChange={(e) => settitlec(e.target.value)} />
        <div className="font-bold text-md py-2">Program Description</div>
        <TextArea value={desc} onChange={(e) => setdesc(e.target.value)} />
        <div className="font-bold text-md py-2">
          Program Description - Chinese
        </div>
        <TextArea value={descc} onChange={(e) => setdescc(e.target.value)} />
        {(programtype === "url" || !programtype) && (
          <>
            <div className="font-bold text-md py-2">Program jump URL</div>
            <Input value={url} onChange={(e) => seturl(e.target.value)} />
          </>
        )}
        <div className="font-bold text-md py-2">Program Priority</div>
        <Input value={priority} onChange={(e) => setpriority(e.target.value)} />
        <div className="font-bold text-md py-2">Program Icon</div>
        <input
          name="file"
          ref={logo_Ref}
          accept="image/*"
          type="file"
          onChange={(e) => handleFileUpload(e, logo_Ref, "logo_url")}
        />
        {icon ? <img src={icon} alt="icon" className="w-24" /> : null}
        <div className="font-bold text-md py-2">Program Picture</div>
        <input
          name="file"
          ref={picture_Ref}
          accept="image/*"
          type="file"
          onChange={(e) => handleFileUpload(e, picture_Ref, "picture_url")}
        />
        {picture ? <img src={picture} alt="picture" className="h-48" /> : null}
      </Modal>
    </>
  );
};
