import { HeadingSection } from "./headingSection";

export const HeadingBlock = ({
  img,
  bg,
  header,
  desc,
  step = 1,
  smallIcon = false,
  fullWidth = false,
}) => {
  console.log({
    img,
    bg,
    header,
    desc,
  });
  return (
    <>
      {/* <div className="flex justify-center overflow-hidden max-h-64">
        <img
          src={bg}
          alt="bg"
          className="w-full md:max-w-screen-xl rounded-lg"
        />
      </div> */}
      <div
        className="flex justify-center h-64 overflow-hidden md:max-w-screen-xl rounded-lg mx-auto"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      ></div>
      <div className="flex justify-center">
        <HeadingSection
          smallIcon={smallIcon}
          img={img}
          header={header}
          desc={desc}
          step={step}
          fullWidth={fullWidth}
        />
      </div>
    </>
  );
};
