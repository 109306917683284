import { useEffect, useState, useRef } from "react";
import { useUser } from "@clerk/clerk-react";
import { Button, Segmented, Table, Input, Select, Form, Upload } from "antd";
import * as XLSX from "xlsx";
import { fetchPost } from "../../utils/fetch";
import { newhost } from "../../config/fetch";
import { upload } from "@vercel/blob/client";

const { TextArea } = Input;

export const EditUniversity = () => {
  const [form] = Form.useForm();
  const [unidata, setUnitData] = useState([]);
  const [currentUni, setCurrentUni] = useState("");
  // const [currentUniInfo, setCurrentUniInfo] = useState({});
  const [imgs, setImgs] = useState({
    logo_url: null,
    school_image_url: null,
    city_image_url: null,
  });

  const logo_url_Ref = useRef(null);
  const school_image_url_Ref = useRef(null);
  const city_image_url_Ref = useRef(null);

  const onFormLayoutChange = (data) => {
    console.log(data);
    console.log(form.getFieldsValue());
  };
  // 拉取学校列表
  useEffect(() => {
    const f = async () => {
      const res = await fetch(newhost + "/uniname");
      const resp = await res.json();
      setUnitData(resp.data);
    };
    f();
  }, []);

  // 初始化获取学校配置信息
  useEffect(() => {
    const f = async () => {
      const res = await fetch(newhost + "/uniinfo" + "?uni=" + currentUni);
      const resp = await res.json();
      // setCurrentUniInfo(resp.data);
      // if (!resp.data) {
      //   console.log("数据出错");
      //   return;
      // }
      form.setFieldValue("city_info", resp.data ? resp.data.city_info : "");
      form.setFieldValue("city_infoc", resp.data ? resp.data.city_infoc : "");
      form.setFieldValue("school_info", resp.data ? resp.data.school_info : "");
      form.setFieldValue("school_infoc", resp.data ? resp.data.school_infoc : "");
      setImgs({
        logo_url: resp.data ? resp.data.logo_url : "",
        school_image_url: resp.data ? resp.data.school_image_url : "",
        city_image_url: resp.data ? resp.data.city_image_url : "",
      });
    };
    f();
  }, [currentUni]);

  const onChange = (value) => {
    setCurrentUni(value);
  };

  const onSearch = (value) => {
    console.log("search:", value);
  };
  // 更新学校配置
  const updateInfo = () => {
    const f = async () => {
      await fetchPost(newhost + "/uniinfo", {
        ...form.getFieldsValue(),
        city_image_url: imgs["city_image_url"],
        logo_url: imgs["logo_url"],
        school_image_url: imgs["school_image_url"],
        university: currentUni,
      });
      alert("update success");
    };
    f();
  };

  const handleFileUpload = async (e, ref, name) => {
    console.log(e, name);
    const file = ref.current.files[0];
    const newBlob = await upload(file.name, file, {
      access: "public",
      handleUploadUrl: newhost + "/upload",
    });
    console.log(newBlob.url);
    setImgs({ ...imgs, [name]: newBlob.url });
  };

  return (
    <div className="pt-5">
      <div className="font-bold pb-2">Select a Univbersity to Config</div>
      <Select
        showSearch
        size="large"
        className="w-[300px]"
        placeholder="Select a Univbersity to Config"
        optionFilterProp="label"
        onChange={onChange}
        onSearch={onSearch}
        options={unidata.map((i) => ({ value: i, label: i }))}
      />
      {currentUni && (
        <Form
          size="large"
          form={form}
          className="mt-10 p-5 border rounded-xl"
          labelCol={{ span: 5 }}
          onValuesChange={onFormLayoutChange}
          wrapperCol={{ span: 18 }}
          layout="horizontal"
          style={{ width: 800 }}
        >
          <Form.Item name="logo_url" label="University's Logo">
            <input
              name="file"
              ref={logo_url_Ref}
              accept="image/*"
              type="file"
              onChange={(e) => handleFileUpload(e, logo_url_Ref, "logo_url")}
            />
            {imgs["logo_url"] ? <img src={imgs["logo_url"]} /> : null}
          </Form.Item>
          <Form.Item name="school_image_url" label="University's Image">
            <input
              name="file"
              ref={school_image_url_Ref}
              type="file"
              accept="image/*"
              onChange={(e) =>
                handleFileUpload(e, school_image_url_Ref, "school_image_url")
              }
            />
            {imgs["school_image_url"] ? (
              <img src={imgs["school_image_url"]} />
            ) : null}
          </Form.Item>
          <Form.Item name="city_info" label="City's Info">
            <TextArea />
          </Form.Item>
          <Form.Item name="city_infoc" label="City's Info (Chinese)">
            <TextArea />
          </Form.Item>
          <Form.Item name="school_info" label="University's Info">
            <TextArea />
          </Form.Item>
          <Form.Item name="school_infoc" label="University's Info (Chinese)">
            <TextArea />
          </Form.Item>
          <Form.Item name="city_image_url" label="City's Image">
            <input
              name="file"
              ref={city_image_url_Ref}
              type="file"
              accept="image/*"
              onChange={(e) =>
                handleFileUpload(e, city_image_url_Ref, "city_image_url")
              }
            />
            {imgs["city_image_url"] ? (
              <img src={imgs["city_image_url"]} />
            ) : null}
          </Form.Item>
          <Button className="w-full" type="primary" onClick={updateInfo}>
            Update
          </Button>
        </Form>
      )}
    </div>
  );
};
